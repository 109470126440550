<!-- layout -->
<template>
  <div class="box">
    <div class="container">
      <div class="leftNavBarPage">
        <div class="left">
          <el-card>
            <img :src="avatar" alt="" />
            <div class="center">
              <el-button class="submit" @click="toUser">
                <router-link to="/info">设置企业名片</router-link>
              </el-button>
            </div>
          </el-card>
          <div class="footerNavBar">
            <el-card>
              <el-menu
                :default-active="activeIndex"
                @select="handleSelect"
                active-text-color="#3C60BC"
                router
              >
                <el-menu-item
                  v-for="(item, index) in menuList"
                  :key="index"
                  class="flex-start"
                  :index="item.value"
                >
                  <span class="title">{{ item.title }}</span></el-menu-item
                >
              </el-menu>
            </el-card>
          </div>
        </div>
        <div class="right">
          <el-card class="leftTitle">
            <div class="addbtn">
              {{ leftTitle }}
              <el-button
                type="primary"
                v-show="
                  activeIndex == 'tubecar' && userInfo.businessStatus == 2
                "
                @click="addCar"
                class="submit"
                >添加机械</el-button
              >
              <el-button
                type="primary"
                v-show="activeIndex == 'need' && userInfo.companyStatus == 2"
                @click="addNeed"
                class="submit"
                >发布需求</el-button
              >
            </div>
          </el-card>
          <div class="userCenterInfo">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSort: 0,
      menuList: [
        { title: '数据中心', value: 'user', id: 0 },
        { title: '车辆管理', value: 'tubecar', id: 1 },
        { title: '我的需求', value: 'need', id: 2 },
        { title: '服务认证', value: 'fuwurenzhen', id: 3 },
        { title: '安全管理', value: 'safety', id: 4 },
        { title: '订单列表', value: 'orderList', id: 5 },
      ],
      activeIndex: 'user',
      userInfo: {},
    }
  },
  computed: {
    leftTitle() {
      return this.activeIndex == 'user'
        ? '数据中心'
        : this.activeIndex == 'tubecar'
        ? '车辆管理'
        : this.activeIndex == 'need'
        ? '我的需求'
        : this.activeIndex == 'fuwurenzhen'
        ? '服务认证'
        : this.activeIndex == 'safety'
        ? '安全管理'
        : this.activeIndex == 'orderList'
        ? '订单列表'
        : '账号管理'
    },
    avatar() {
      return this.$store.state.user.avatar
        ? this.$store.state.user.avatar
        : require('@/assets/images/avatar.png')
    },
  },
  watch: {
    $route(to, from) {
      if (to.query.type || to.query.types) {
        this.currentSort = sessionStorage.getItem('setIndex')
        this.activeIndex = sessionStorage.getItem('setActive')
      }else{
        this.activeIndex = to.path.slice(1)
      }
    },
  },
  created() {
    if (sessionStorage.getItem('setActive') != '') {
      this.currentSort = sessionStorage.getItem('setIndex')
      this.activeIndex = sessionStorage.getItem('setActive')
    }
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (this.userInfo.companyStatus == 2) {
      this.menuList = this.menuList.filter((item, index) => {
        return index != 1
      })
    } else if (this.userInfo.businessStatus == 2) {
      this.menuList = this.menuList.filter((item, index) => {
        return index != 2
      })
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.menuList.filter((item, index) => {
        if (item.value == key) {
          this.currentSort = index
          this.activeIndex = key
          sessionStorage.setItem('setActive', key)
          sessionStorage.setItem('setIndex', index)
        }
      })
    },
    addCar() {
      this.$store.commit('user/SET_FORMDATA', {})
      this.$router.push({
        path: '/addCar',
        query: {
          type: 1,
        },
      })
    },
    addNeed() {
      this.$router.push({
        path: '/addNeed',
        query: {
          type: 1,
        },
      })
    },
    toUser() {
      this.activeIndex = 'info'
      this.currentSort = 6
      sessionStorage.setItem('setActive', this.activeIndex)
      sessionStorage.setItem('setIndex', this.currentSort)
    },
  },
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  padding: 20px 0;
  .el-button {
    width: 170px;
  }
  .leftNavBarPage {
    display: flex;
    font-size: 14px;
    .left {
      justify-content: center;
      text-align: center;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .center {
        margin-top: 20px;
        .el-button {
          background-color: #fcb110;
          color: #fff;
          border: 1px solid #fcb110;
        }
      }

      .footerNavBar {
        margin-top: 20px;
        .el-menu {
          border-right: none;
        }
        li {
          line-height: 50px;
          border-bottom: 1px solid #ccc;
          cursor: pointer;
          transition: all 0.2s;
        }
        li:nth-child(6) {
          border: none;
        }
        li:hover {
          color: #3c60bc;
        }
        ::v-deep .el-card__body {
          padding: 0;
        }
      }
    }
    .right {
      margin-left: 20px;
      width: 80%;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .el-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .leftTitle {
        margin-bottom: 20px;
      }
      .addbtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .div2 {
        font-size: 14px;
        color: #999999;
        margin-top: 30px;
      }
      .contents {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        img {
          width: 100%;
          height: 200px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .left {
          width: 48%;
          background-image: url('../../assets/images/activity-bg-3.png');
          background-position: center;
          background-size: cover;
          text-align: left;
          padding: 60px 0 20px 30px;

          .approve {
            display: flex;
            align-items: center;
            div:nth-child(1) {
              font-size: 18px;
              font-weight: bold;
            }
            .noapprove {
              margin-left: 20px;
              background-color: #fdf4df;
              color: #fcb110;
              padding: 2px 10px;
              border-radius: 50px;
              font-size: 14px;
              font-weight: bold;
            }
          }
          .btn {
            text-align: center;
            margin-top: 30px;
            .el-button {
              border: 1px solid #3c60bc;
              color: #3c60bc;
            }
          }
        }
      }
    }
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import { menus } from '@/utils/common'
import store from '@/store'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

// let routes = asyncRouters(menus)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: '首页',
      path: '/',
      component: () => import('@/views/home')
    },
    {
      name: '寻租机械',
      path: '/result',
      component: () => import('@/views/result')
    },

    {
      name: '寻租需求',
      path: '/needs',
      component: () => import('@/views/needs')
    },


    {
      name: '登录',
      path: '/login',
      hidden: true,
      component: () => import('@/views/login')
    },
    {
      name: '注册',
      path: '/register',
      hidden: true,
      component: () => import('@/views/register')
    },
    {
      name: '',
      path: '/forgetPassword',
      hidden: true,
      component: () => import('@/views/forgetPassword')
    },
    {
      name: '个人中心',
      path: '/userCenter',
      hidden: true,
      component: () => import('@/views/user/userCenter'),
      children: [
        {
          name: '账号管理',
          path: '/info',
          hidden: true,
          component: () => import('@/views/user/info')
        },
        {
          name: '个人中心',
          path: '/user',
          hidden: true,
          component: () => import('@/views/user/user')
        },
        {
          name: '车辆管理',
          path: '/tubecar',
          hidden: true,
          component: () => import('@/views/user/car/tubecar')
        },
        {
          name: '我的需求',
          path: '/need',
          hidden: true,
          component: () => import('@/views/user/need/need')
        },
        {
          name: '服务认证',
          path: '/fuwurenzhen',
          hidden: true,
          component: () => import('@/views/user/fuwurenzhen/fuwurenzhen'),
        },
        {
          name: '企业认证',
          path: '/firm',
          hidden: true,
          component: () => import('@/views/user/fuwurenzhen/firm'),
        },
        {
          name: '车主认证',
          path: '/car',
          hidden: true,
          component: () => import('@/views/user/fuwurenzhen/car'),
        },
        {
          name: '安全管理',
          path: '/safety',
          hidden: true,
          component: () => import('@/views/user/safety')
        },
        {
          name: '车辆订单',
          path: '/carOrder',
          hidden: true,
          component: () => import('@/views/user/carOrder')
        },
        {
          name: '企业订单',
          path: '/firmOrder',
          hidden: true,
          component: () => import('@/views/user/firmOrder')
        },
        {
          name: '订单列表',
          path: '/orderList',
          hidden: true,
          component: () => import('@/views/user/orderList/orderList')
        },

      ]
    },
    {
      name: '添加车辆',
      path: '/addCar',
      hidden: true,
      component: () => import('@/views/user/car/addCar')
    },
    {
      name: '发布需求',
      path: '/addNeed',
      hidden: true,
      component: () => import('@/views/user/need/addNeed')
    },
    {
      name: 'warn',
      path: '/warn',
      hidden: true,
      component: () => import('@/views/warn')
    },
    {
      name: '寻租机械——详情',
      path: '/result/detail1',
      hidden: true,
      component: () => import('@/views/result/detail1')
    },
    {
      name: '寻租需求——详情',
      path: '/needs/detail1',
      hidden: true,
      component: () => import('@/views/needs/detail1')
    },
    {
      name: '需求接单',
      path: '/needs/takeOrders',
      hidden: true,
      component: () => import('@/views/needs/takeOrders')
    },
    {
      name: '车辆下单',
      path: '/hire',
      hidden: true,
      component: () => import('@/views/result/hire')
    },
    {
      name: '订单列表详情',
      path: '/orderDetail',
      hidden: true,
      component: () => import('@/views/user/orderList/orderDetail')
    },
    {
      name: '修改设备价格',
      path: '/deviceShow',
      hidden: true,
      component: () => import('@/views/user/orderList/deviceShow')
    },
    {
      name: '咨询列表',
      path: '/consult',
      component: () => import('@/views/consult/index')
    },
    {
      name: '咨询详情',
      path: '/consultDetail',
      component: () => import('@/views/consult/detail')
    },
  ]
})
router.beforeEach((to, from, next) => {
  if (to.path == '/userCenter' || to.path == '/result/userCenter' || to.path == '/needs/userCenter') {
    sessionStorage.setItem('setActive', 'user')
    sessionStorage.setItem('setIndex', 0)
    next({ path: '/user' })
  }
  let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
  let token = getToken()
  if (token) {
    if (userInfo.companyStatus == 0 && userInfo.businessStatus == 0) {
      if (to.path == '/upMechanical') {
        next({
          path: '/warn',
          query: {
            message: '请先完成服务认证',
          }
        })
      }
    }
    if (userInfo.companyStatus == 2) {
      if (to.path == '/upMechanical') {
        next({
          path: '/warn',
          query: {
            message: '该功能仅车主用户浏览',
          }
        })
      }
    } else if (userInfo.businessStatus == 2) {
      if (to.path == '/upMechanical') {
        next({ path: '/addCar' })
      }
    }
    if (to.path == '/login') {
      return
    }
  } else {
    if (to.path == '/upMechanical') {
      next({
        path: '/warn',
        query: {
          message: '请先登录',
        }
      })
    }
  }

  next()
})

// function asyncRouters(menus) {

//   let asyncRouters = []
//   for (let i in menus) {
//     asyncRouters.push({
//       name: menus[i].name,
//       path: menus[i].path,
//       component: menus[i].component
//     })
//   }
//   return asyncRouters
// }
export default router

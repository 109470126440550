import { getToken, setToken, removeToken } from '@/utils/auth'
import { login } from '@/api/login'

const state = {
  hasLogin: false,
  token: getToken(),
  formData: {},
  avatar: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_FORMDATA: (state, formData) => {
    {
      state.formData = formData
    }
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        commit('SET_TOKEN', res.data.token)
        setToken(res.data.token)
        resolve()
      }).catch(err => {
        reject(err)
      })

    })
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken() // must remove  token  first
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


import request from '@/utils/request.js'
// 登录模块

// 获取验证码
export function getTheCode (params) {
  return request({
    url: '/app/getCode',
    method: 'get',
    params
  })
}

// 获取图形验证码
export function getImageCode () {
  return request({
    url: '/app/getImageCode',
    method: 'get'
  })
}

// 发送短信验证码
export function reportSms (data) {
  return request({
    url: '/app/reportSms',
    method: 'post',
    data
  })
}

// 忘记密码
export function forgetPassword (data) {
  return request({
    url: '/app/forgetPassword',
    method: 'post',
    data
  })
}
// 修改密码
export function updatePassword (data) {
  return request({
    url: '/user/updatePassword',
    method: 'post',
    data
  })
}

// 登录
export function login (data) {
  return request({
    url: '/app/login',
    method: 'post',
    data
  })
}

// 首页数据查询
export function getAllData () {
  return request({
    url: '/app/index',
    method: 'get'
  })
}

// 消息列表查询
export function getAll (page,pageSize) {
  return request({
    url: '/open/notice/getAll?page=' + page + '&pageSize=' + pageSize,
    method: 'get'
  })
}
// 消息列表详情
export function noticeId (id) {
  return request({
    url: '/open/notice/getById?noticeId=' + id,
    method: 'get'
  })
}
export { menus } from '@/utils/common'

const state = {
    activeIndex: localStorage.getItem('activeIndex') || '/'
}

const mutations = {
    SET_ACTIVE_INDEX (state, activeIndex) {
        state.activeIndex = activeIndex
    }
}

const actions = {
    setActiveIndex ({ commit }, val) {
        commit('SET_ACTIVE_INDEX', val)
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


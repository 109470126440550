import userCenter from '@/views/user/userCenter'
// 菜单数据
export const menus = [
  {
    name: '首页',
    path: '/',
    component: () => import('@/views/home')
  },
  {
    name: '寻租机械',
    path: '/result',
    component: () => import('@/views/result')
  },
  {
    name: '上传机械',
    path: '/upMechanical',
    component: () => import('@/views/upMechanical')
  },
  {
    name: '寻租需求',
    path: '/needs',
    component: () => import('@/views/needs')
  },

  {
    name: '登录',
    path: '/login',
    hidden: true,
    component: () => import('@/views/login')
  },
  {
    name: '注册',
    path: '/register',
    hidden: true,
    component: () => import('@/views/register')
  },
  {
    name: '',
    path: '/forgetPassword',
    hidden: true,
    component: () => import('@/views/forgetPassword')
  },
  {
    name: '个人中心',
    path: '/userCenter',
    hidden: true,
    component: userCenter,
    children:[
      {
        name: '账号管理',
        path: '/info',
        hidden: true,
        component: () => import('@/views/user/info')
      },
      {
        name: '车辆管理',
        path: '/info',
        hidden: true,
        component: () => import('@/views/user/info')
      },
    ]
  },
  {
    name: '添加车辆',
    path: '/addCar',
    hidden: true,
    component: () => import('@/views/user/car/addCar')
  },
  {
    name: '发布需求',
    path: '/addNeed',
    hidden: true,
    component: () => import('@/views/user/need/addNeed')
  },
  {
    path: '/result/detail1',
    hidden: true,
    component: () => import('@/views/result/detail1')
  },

  {
    path: '/needs/detail1',
    hidden: true,
    component: () => import('@/views/needs/detail1')
  },
]

// 平台数据
export const data1 = [
  {
    num: 86732,
    unit: '个',
    text: '成果'
  },
  {
    num: 23467,
    unit: '场',
    text: '活动'
  },
  {
    num: 744932,
    unit: '家',
    text: '企业'
  },
  {
    num: 89,
    unit: '所',
    text: '高校院所'
  },
  {
    num: 34687,
    unit: '个',
    text: '人才引进'
  },
  {
    num: 1789,
    unit: '个',
    text: '项目'
  }
]

// 服务动态
export const data2 = [
  {
    title: '产学研合作服务协议',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170249350267.png-c210.165',
    type: '远程服务',
    money: '面议',
    msg: '产学研合作协议服务，是北海科技交易平台针对企业发展过程中需要与高校院所进行产学研合作的需求，提供与高校一级行政单位产学研合作渠道，帮助企业建立技术背书。',
    time: '2021-02-12'
  },
  {
    title: '智能制造服务平台',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170311806381.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '以构建智能制造创新服务体系为目标，整合线上线下资源，精选智能制造优质服务商，解决企业智能化改造需求，助力区域制造产业向数字化升级、高端化发展。',
    time: '2021-02-10'
  },
  {
    title: '区域技术要素市场',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203165128579570.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '基于信息中介 —— 专项服务 —— 全程代理的发展路径，为技术经理人提供培训认证、备案管理、业务支撑等服务，为区域培养一支专业化程度高、服务能力强的技术经理人队伍。',
    time: '2021-02-08'
  },
  {
    title: '经纪人培育',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170417175805.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '针对校地合作的环节复杂性、合作持续难、项目落地难等问题，由政府主管部门指定北海科技交易平台作为运营商，通过灵活的合作模式，为地方引进高校，充分利用高校资源服务地方创新工作，提升校地合作的效率和实效。',
    time: '2021-02-05'
  },
  {
    title: '高校技术转移运营中心',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170212956194.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '助力政府打造多主体共享、多场景应用的线下科技创新共享载体，为科技展厅的提升人气、提高使用率提供综合解决方案。',
    time: '2021-02-05'
  },


]

// 科技政策
export const data3 = [
  {
    title: '民大发〔2021〕15号 广西民族大学关于印发《广西民族大学科学研究奖励办法补充规定》的通知',
    time: '2021-01-12'
  },
  {
    title: '	关于组织学习《中华人民共和国生物安全法》的通知',
    time: '2020-01-05'
  },
  {
    title: '	关于组织学习科技部《科学技术活动评审工作中请托行为处理规定（试行）》（国科发监〔2020〕360号）..',
    time: '2020-12-31'
  },
  {
    title: '关于组织学习自治区科技厅《广西壮族自治区应用数学中心组建方案》的通知',
    time: '2020-12-01'
  },
  {
    title: '关于组织学习《广西加强“从0到1”基础研究的实施意见》（桂科政字〔2020〕125号）的通知',
    time: '2020-11-19'
  },

]

// 技术市场
export const jsData1 = [
  {
    pic: require('@/assets/images/icon-2-1.png'),
    title: '运动器材旋转双工位焊接工作站',
    img: 'https://stat0.keyibao.com/upload/2021/01/11/20210111162024020595.png',
    taps: ['信息系统集成', '物联网技术服务'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '利用普通的工人替代专业的工人，提高产能的增加和整体的焊接质量，使得产品更加美观'
  },
  {
    pic: require('@/assets/images/icon-2-2.png'),
    title: '卫浴陶瓷行业MES系统解决方案',
    img: 'https://stat0.keyibao.com/upload/2021/01/07/20210107171145328808.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '卫浴陶瓷行业MES系统解决方案，以信息化建设为契机，从接单、生产、流程控制一直到产品完成，主动收集及监控制造过程中所产生的生产数据，达成全生命周期全要素的透明化、协同化、智能化管理，帮助企业解决可追溯性、生产控制和企业级系统集成问题，构建更加高效、内控的业务流程。'
  },
  {
    pic: require('@/assets/images/icon-2-3.png'),
    title: '工艺参数优化解决方案',
    img: 'https://stat0.keyibao.com/upload/2020/12/22/20201222151928297900.png',
    taps: ['工艺参数优化解决方案'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '根据产线质量检测环节的反馈(可与RealAl工业智能检测方案结合)，以及实际加工参数、\n环境变量等信息，实时推断并推荐最优的加工工艺参数。对制造设备实现自动调节，释放人工成本，利用AI算法固化专家知识，在生产过程中自动调节工艺参数，提升生产效率和良品率，减轻人工调节负担和专家依赖，使生产效果达到最优。'
  },
  {
    pic: require('@/assets/images/icon-2-4.png'),
    title: '工业光伏组件智能检测',
    img: 'https://stat0.keyibao.com/upload/2020/12/22/20201222151336376906.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '光伏组件智能检测系统实现EL和外观图像的自动识别，在解放视觉检测人工的同时，帮助生产光伏组件客户解决检测环节的效率瓶颈，并提升检测的准确性。通过EL(electroluminescent)测试，利用电致发光原理，对光伏组件反面通电，并通过红外相机拍摄发光组件图像，对隐裂(线状、交叉、树状),裂片、混档、黑斑、断栅、虛焊、过焊、黑片/亮片、黑边、划痕等缺陷进行检测。而在外观检测方面，则通过对光伏组件进行直接拍照，获取清晰的电池片、电池串边界，从而检查组件的排版缺陷。外观检测同时可以检查到异物和露白。'
  },
  {
    pic: require('@/assets/images/icon-2-5.png'),
    title: 'PHM设备健康诊断与预测性维护',
    img: 'https://stat0.keyibao.com/upload/2020/12/22/20201222150957845362.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '为了保障水电站安全运行，中国电建需要对水坝，尤其是传感器未安装或已损坏坝段的结构风险进行有效预警。通过应用RealAI的设备健康管理解决方案，对传感器信号进行智能补全与预测，评价大坝各坝段结构运行状态，帮助了中国电建有效预测坝体安全隐患、优化运行效率。'
  },
  {
    pic: require('@/assets/images/icon-2-6.png'),
    title: '智慧热能应用云',
    img: 'https://stat0.keyibao.com/upload/2020/12/21/20201221164156138909.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '智慧热能应用云解决方案，通过采集烘烤生产设备全局生产数据，加密传输到云端服务器，建立烘烤生产数字挛生模型，人工智能在线寻找优化生产参数，给客户带来安全生产、节能降耗、环保排放和品质提升的综合效益。", "hangye": "I,65,651", "check_status": 1, "check_name": "审核通过。'
  },
  {
    pic: require('@/assets/images/icon-2-7.png'),
    title: '校园智能照明综合服务管理平台',
    img: 'https://stat0.keyibao.com/upload/2020/12/21/20201221153307333432.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '针对教育照明提供灯控解决方案，包含\n1.包含智能灯控终端控制面板、\n2.FC-WG 系列工业级智能网关、\n3.智能控制多功能电源、\n4.综合管理服务平台\n5.照明控制终端APP\n6.功能支持：\n教室多情境模式，每种模式设置教室灯和黑板灯的不同的亮度值\n自主调节亮度值，根据环境光亮度，自主调节照明亮度\n设置自动执行策略，在指定时间下，多教室多楼栋的无人化开关\n教室远程开关等，减少后勤管理流程，提升管理效率\n教室灯和开关在不同模式下联动开关。'
  },
  {
    pic: require('@/assets/images/icon-2-8.png'),
    title: '法院智慧通行管理系统',
    img: 'https://stat0.keyibao.com/upload/2020/12/21/20201221141213753930.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '智慧法院是依托现代人工智能，围绕司法为民、公正司法，坚持司法规律、体制改革与技术变革相融合，以高度信息化方式支持司法审判、诉讼服务和司法管理，实现全业务网上办理、全流程依法公开、全方位智能服务的人民法院组织、建设、运行和管理形态。\n法院作为社会矛盾的集中地,严格的身份核验和安检,不仅是保障法院工作人员的安全,更是对参与人、当事人的人身安全的负责。瑞为技术一直致力于出入口管理方案产品,经过深入了解法院外部人员进出管理需求,对原有方案进行升级,提出了法院智慧通行新模式。'
  },
  {
    pic: require('@/assets/images/icon-2-9.png'),
    title: '研磨车间单件流少人化改造',
    img: 'https://stat0.keyibao.com/upload/2020/12/18/20201218163748613545.png',
    taps: ['其它信息技术服务业'],
    money: '暂无报价',
    map: '广西 桂林',
    msg: '针对车间的制程做价值流分析，根据每个工站的生产节拍计算产能平衡。将机群式布局重新设计为流程式布局，走单件流。从而减少在制品库存、减少搬运人工、缩短交期'
  },
]

// 专利证书
export const jsData2 = [
  {
    title: '一种升降式儿童餐椅',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '201921547737X',
    type: '实用新型专利'
  },
  {
    title: '一种肥料生产用原材料配料装置',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '2019216712127',
    type: '实用新型专利'
  },
  {
    title: '木塑板用木屑回收装置',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '201921594865X',
    type: '实用新型专利'
  },
  {
    title: '一种方便使用的铝合金材料切割装置',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '2019220037505',
    type: '实用新型专利'
  },
  {
    title: '一种具有防尘功能的纸管管切装置',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '2019218700730',
    type: '实用新型专利'
  },
  {
    title: '一种自动截止的防泄漏阀门',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '201922024444X',
    type: '实用新型专利'
  },
  {
    title: '一种快速散热的按摩冰凉枕',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '201921547737X',
    type: '实用新型专利'
  },
  {
    title: '一种舞台灯光散热装置',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '2019215683767',
    type: '实用新型专利'
  },
  {
    title: '一种工业用纸印花装置',
    img: require('@/assets/images/result_pro_2.jpg'),
    money: '面议',
    map: '广西 桂林',
    code: '2019223173588',
    type: '实用新型专利'
  },
]

// 前沿技术
export const jsData3 = [
  {
    title: '一种干法加煤渣膜气化炉',
    type: '发明专利',
    pregress: '正在研发',
    use: '先进制造与自动化',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种带有回热系统的高压粉煤气化炉',
    type: '发明专利',
    pregress: '正在研发',
    use: '先进制造与自动化',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种基于差分定位技术的输电线路监测系统',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '基于微纳磁粒的血液处理工作站及其控制方法',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '轴流通风机产品开发',
    type: '非专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种实现基于机群结构的高性能服务器动态部署方法',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '基于波形系数的变电站异常数据识别及恢复方法',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '热交换器性能测试时热平衡相对误差界的确定方法',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '形变强化钨合金高密度脉冲电流强韧化处理方法',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  }
]

// 成果库
export const jsData4 = [
  {
    title: '根除淋巴结核技术',
    type: '非专利',
    pregress: '已有样品',
    use: '生物与新医药',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '煤矿通风救援应急通道',
    type: '实用新型专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种红酒杯清洗装置',
    type: '实用新型专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种低风速高效授粉器',
    type: '发明专利',
    pregress: '已有样品',
    use: '现代农业',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一个治疗老胃病的民族验方',
    type: '发非专利',
    pregress: '正在研发',
    use: '生物与新医药',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一个治疗腰腿痛的胶囊（正骨胶囊）',
    type: '非专利',
    pregress: '正在研发',
    use: '生物与新医药',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种智能跑步机',
    type: '实用新型专利',
    pregress: '正在研发',
    use: '先进制造与自动化',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '一种舒适、卫生、方便座便器',
    type: '非专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  },
  {
    title: '网钓渔具',
    type: '发明专利',
    pregress: '正在研发',
    use: '其它',
    sale: '完全转让',
    map: '广西 桂林'
  }
]

// 精选需求
export const data5 = [
  { "datatype": 1, "url": "/rdc/demand/zeojcbbe.html", "demandname": "新型模切胶带材料", "inputbudget": "01万元", "demandtype": "技术难题解决", "location": "江苏 南京 栖霞区", "demandstatustext": "待解决", "hangyes": "其他新材料技术 ", "demandtag": "", "updatetime": "2021-01-20" }, { "datatype": 1, "url": "/rdc/demand/temkuyye.html", "demandname": "高效换热器技术、均温板技术", "inputbudget": "面议", "demandtype": "技术难题解决", "location": "江苏 泰州 海陵区", "demandstatustext": "待解决", "hangyes": "电子元器件 ", "demandtag": "", "updatetime": "2021-01-19" }, { "datatype": 1, "url": "/rdc/demand/sjjiqxxj.html", "demandname": "洗碗机下碗篮提升机构研发", "inputbudget": "面议", "demandtype": "技术难题解决", "location": "江苏 泰州 海陵区", "demandstatustext": "待解决", "hangyes": "其他机械 ", "demandtag": "", "updatetime": "2021-01-19" }, { "datatype": 1, "url": "/rdc/demand/rzxnvrrz.html", "demandname": "高温阻尼器研发", "inputbudget": "面议", "demandtype": "技术难题解决", "location": "江苏 泰州 海陵区", "demandstatustext": "待解决", "hangyes": "其他机械 ", "demandtag": "", "updatetime": "2021-01-19" }, { "datatype": 1, "url": "/rdc/demand/kmwshmrm.html", "demandname": "高品质双相不锈钢系列", "inputbudget": "70万元", "demandtype": "技术难题解决", "location": "江苏 泰州 ", "demandstatustext": "待解决", "hangyes": "金属材料 ", "demandtag": "", "updatetime": "2021-01-18" }, { "datatype": 1, "url": "/rdc/demand/jrxitfrr.html", "demandname": "飞行数据分析系统软件开发", "inputbudget": "面议", "demandtype": "技术难题解决", "location": "广东 惠州 仲恺高新区", "demandstatustext": "待解决", "hangyes": "飞行器 ", "demandtag": "", "updatetime": "2021-01-18" }
]

// 热门服务
export const data6 = [
  {
    title: '产学研合作服务协议',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170249350267.png-c210.165',
    type: '远程服务',
    money: '面议',
    msg: '产学研合作协议服务，是北海科技交易平台针对企业发展过程中需要与高校院所进行产学研合作的需求，提供与高校一级行政单位产学研合作渠道，帮助企业建立技术背书。',
  },
  {
    title: '智能制造服务平台',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170311806381.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '以构建智能制造创新服务体系为目标，整合线上线下资源，精选智能制造优质服务商，解决企业智能化改造需求，助力区域制造产业向数字化升级、高端化发展。',
  },
  {
    title: '区域技术要素市场',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203165128579570.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '基于信息中介 —— 专项服务 —— 全程代理的发展路径，为技术经理人提供培训认证、备案管理、业务支撑等服务，为区域培养一支专业化程度高、服务能力强的技术经理人队伍。',
  },
  {
    title: '经纪人培育',
    img: 'https://stat0.keyibao.com/upload/2020/12/03/20201203170417175805.png-c210.165',
    type: '远程服务/直接对接',
    money: '面议',
    msg: '针对校地合作的环节复杂性、合作持续难、项目落地难等问题，由政府主管部门指定北海科技交易平台作为运营商，通过灵活的合作模式，为地方引进高校，充分利用高校资源服务地方创新工作，提升校地合作的效率和实效。',
  },

]

// 技术市场
export const data7 = [
  {
    company: '湖北创优企科技服务有限公司',
    logo: 'https://upload.1633.com/upload/2020/04/09/20200409193153161753.jpg',
    type: '技术转移示范机构-市级',
    taps: ['技术转移', '知识产权', '其它'],
    map: '湖北 武汉 洪山区',
    msg: '企业认证、项目申报、技术转移、成果转化',
  },
  {
    company: '武汉市天使翼技术转移示范机构',
    logo: 'https://upload.1633.com/upload/users/20181205/20181205135646836278.jpg',
    type: '众创空间-国家备案',
    taps: ['创业孵化', '技术转移', '知识产权'],
    map: '湖北 武汉 东湖新技术开发区',
    msg: '科创成果转化服务、投融资对接服务、品牌活动承办、企业办公租赁、专利知识产权服务'
  },
  {
    company: '武汉君成汇科技咨询有限公司',
    logo: 'https://upload.1633.com/upload/2020/08/08/20200808142907042960.png',
    type: '技术转移示范机构-市级',
    taps: ['技术转移', '其它'],
    map: '湖北 武汉 蔡甸区',
    msg: '企业政策解读技术转移服务高新技术认定申报科技项目申报企校合作对接等'
  },
  {
    company: '武汉欣博信达技术转移平台',
    logo: 'https://upload.1633.com/upload/2020/07/28/20200728144654603915.png',
    type: '技术转移示范机构-市级',
    taps: ['技术转移', '知识产权', '咨询评估'],
    map: '湖北 武汉 东西湖区',
    msg: '技术成果对接、人才对接、高新技术企业认定、科技咨询及企业科技服务、知识产权服务等'
  },
  {
    company: '烽火创新谷',
    logo: 'https://upload.1633.com/upload/2020/07/28/20200728122522845479.png',
    type: '技术转移示范机构-省级, 科',
    taps: ['技术转移', '知识产权', '咨询评估'],
    map: '湖北 武汉 洪山区',
    msg: '根据技术、市场、人才的具体情况，有针对性地选择适合园区企业的具体方案进行技术转移服务。'
  },

  {
    company: '湖北博士百川信息科技有限公司',
    logo: 'https://upload.1633.com/upload/2020/07/24/20200724084743347560.png',
    type: '技术转移示范机构-市级',
    taps: ['技术转移', '知识产权', '咨询评估'],
    map: '湖北 武汉 武昌区',
    msg: '提供人才推荐、技术转移、知识产权、平台认定、品牌建设、政策应用、项目申报、财税筹划等一站式服务。'
  },
]

// 热门活动
export const data8 = [
  {
    title: '2020年武汉市科技成果转化系列—江汉区成果转化线下活动',
    pic: 'https://upload.1633.com/upload/2020/12/16/20201216104044829996.png-c300.200',
    status: '已结束',
    time: '2020年12月03日 14:00-17:00',
    company: '江汉区科学技术和经济信息化局'
  },
  {
    title: '武汉市科技成果转化系列活动——创天地化工专场',
    pic: 'https://upload.1633.com/upload/2020/11/28/20201128183629067129.jpg-c300.200',
    status: '已结束',
    time: '2020年09月25日 10:00-12:00',
    company: '武汉市科学技术局	 武汉市科技成果转化局'
  },
  {
    title: '武汉市科技成果转化武汉先进院综合专场',
    pic: 'https://upload.1633.com/upload/2020/12/08/20201208091919013684.png-c300.200',
    status: '已结束',
    time: '2020年11月13日 14:30-16:30',
    company: '武汉市科学技术局、武汉市科技成果转化局'
  },
  {
    title: '武汉市科技成果转化活动—长江青年城专场（线上）',
    pic: 'https://upload.1633.com/upload/2020/12/07/20201207171955068770.jpg-c300.200',
    status: '已结束',
    time: '2020年11月06日 14:30-17:30',
    company: '武汉市科学技术局  武汉市科技成果转化局'
  },
  {
    title: '武汉市科技成果转化系列活动 烽火创新谷专场',
    pic: 'https://upload.1633.com/upload/2020/12/07/20201207135947514211.png-c300.200',
    status: '已结束',
    time: '2020年11月26日 14:45-17:00',
    company: '武汉市科学技术局 武汉市科技成果转化局'
  },
  {
    title: '武汉市科技成果转化系列活动 烽火创新谷线上（二）专场',
    pic: 'https://upload.1633.com/upload/2020/12/05/20201205143441499451.jpg-c300.200',
    status: '已结束',
    time: '2020年11月17日 15:00-17:04',
    company: '武汉市科学技术局 武汉市科技成果转化局'
  },
  {
    title: '武汉市科技成果转化系列斗鱼专场',
    pic: 'https://upload.1633.com/upload/2020/12/04/20201204155909285658.png-c300.200',
    status: '已结束',
    time: '2020年11月23日 15:00-17:00',
    company: '武汉市科学技术局、武汉市科技成果转化局'
  },
  {
    title: '2020年武汉市科技成果转化系列活动-人工智能和农业专场',
    pic: 'https://upload.1633.com/upload/2020/12/16/20201216104044829996.png-c300.200',
    status: '已结束',
    time: '2020年11月27日 14:00-17:30',
    company: '武汉市科学技术局、武汉市科技成果转化局'
  }
]

// 科技政策
export const data9 = [
  {
    title: '民大发〔2021〕15号 广西民族大学关于印发《广西民族大学科学研究奖励办法补充规定》的通知',
    time: '2021-01-12'
  },
  {
    title: '	关于组织学习《中华人民共和国生物安全法》的通知',
    time: '2020-01-05'
  },
  {
    title: '	关于组织学习科技部《科学技术活动评审工作中请托行为处理规定（试行）》（国科发监〔2020〕360号）..',
    time: '2020-12-31'
  },
  {
    title: '关于组织学习自治区科技厅《广西壮族自治区应用数学中心组建方案》的通知',
    time: '2020-12-01'
  },
  {
    title: '关于组织学习《广西加强“从0到1”基础研究的实施意见》（桂科政字〔2020〕125号）的通知',
    time: '2020-11-19'
  },
  {
    title: '民大〔2020〕259号 关于印发《广西民族大学科学技术研究档案管理办法修订》的通知',
    time: '2020-09-28'
  },
]

// 通知
export const data10 = [
  {
    title: '科技处转发自治区科协关于开展2021年中国工程院院士候选人推选工作的通知',
    time: '2021-02-05'
  },
  {
    title: '广西民族大学2020年下半年校级科研项目（自然科学类）结题评审结果公示',
    time: '2021-02-05'
  },
  {
    title: '广西民族大学2020年下半年校级科研项目（自然科学类）结题评审结果公示',
    time: '2021-02-03'
  },
  {
    title: '关于组织学习自治区科技厅印发2021年“港澳台英才聚桂计划”项目指南和项目申报的通知',
    time: '2021-02-01'
  },
  {
    title: '科技处关于转发《国家自然科学基金优秀青年科学基金项目（海外）项目指南》的通知',
    time: '2021-02-01'
  }
]



// 科技金融
export const data12 = [
  {
    logo: require('@/assets/table-logo/o1.png'),
    nick: '	青实资本',
    company: '西安青实资本管理有限公司',
    tz: '初创期、成熟期',
    type1: 'IT',
    type2: 'AI',
    type3: '物联网',
    num: 0
  },
  {
    logo: require('@/assets/table-logo/o2.jpg'),
    nick: '	清科资管',
    company: '北京清科资产管理中心（有限合伙）',
    tz: '成熟期',
    type1: '医药',
    type2: '',
    type3: '',
    num: 1
  },
  {
    logo: require('@/assets/table-logo/o3.jpg'),
    nick: '	清科集团',
    company: '清科集团',
    tz: '初创期、成熟期',
    type1: '',
    type2: '',
    type3: '',
    num: 4
  },
  {
    logo: require('@/assets/table-logo/o4.gif'),
    nick: 'IDG资本',
    company: 'IDG资本投资顾问（北京）有限公司',
    tz: '初创期',
    type1: '5G',
    type2: '生活服务',
    type3: '医疗服务',
    num: 760
  },
  {
    logo: require('@/assets/table-logo/o5.png'),
    nick: '腾讯投资',
    company: '腾讯投资',
    tz: '初创期、成熟期、扩张期、种子期	',
    type1: '游戏',
    type2: 'AI',
    type3: '',
    num: 182
  },
  {
    logo: require('@/assets/table-logo/o6.jpg'),
    nick: '	红杉中国',
    company: '红杉资本中国基金）',
    tz: '不详',
    type1: '医药',
    type2: '机器人',
    type3: '旅游',
    num: 614
  },
]
export const jrData2 = [
  {
    logo: require('@/assets/table-logo/1.jpg'),
    company: '神州慧安',
    service: '企业服务',
    type1: 'A轮',
    type2: '金额未透露',
    type3: '融资完成',
    time: '不详',
    status: 0,
  }, {
    logo: require('@/assets/table-logo/2.jpg'),
    company: '胖猫云',
    service: '工具软件',
    type1: 'A轮',
    type2: '金额未透露',
    type3: '融资完成',
    time: '不详',
    status: 0,
  }, {
    logo: require('@/assets/table-logo/3.jpg'),
    company: '易马达e换电绿色智慧能源网络服务平台',
    service: '环保/能源',
    type1: '其它轮',
    type2: '金额未透露',
    type3: '融资完成',
    time: '2015年',
    status: 0,
  }, {
    logo: require('@/assets/table-logo/4.jpg'),
    company: '智能明暗调光液晶膜汽车玻璃	',
    service: '其它',
    type1: '暂不融资',
    type2: '',
    type3: '',
    time: '2014年',
    status: 1,
  }, {
    logo: require('@/assets/table-logo/5.jpg'),
    company: '移动操作机器人',
    service: '机器人',
    type1: '不详',
    type2: '',
    type3: '',
    time: '2017年',
    status: 1,
  }, {
    logo: require('@/assets/table-logo/6.jpg'),
    company: '无反光板激光前移叉车AGV',
    service: '不详',
    type1: '不详',
    type2: '',
    type3: '',
    time: '2011年',
    status: 1,
  }
]

// 科技金融
export const data13 = [
  {
    bg: require('@/assets/images/bg-8-1.png'),
    icon: require('@/assets/images/icon-8-1.png'),
    title: '高校',
    tip: '企业的创新与突破，我们来解决',
  },
  {
    bg: require('@/assets/images/bg-8-2.png'),
    icon: require('@/assets/images/icon-8-2.png'),
    title: '专家',
    tip: '企业的创新与突破，我们来解决',
  },
  {
    bg: require('@/assets/images/bg-8-6.png'),
    icon: require('@/assets/images/icon-8-6.png'),
    title: '园区',
    tip: '企业的创新与突破，我们来解决',
  },
  {
    bg: require('@/assets/images/bg-8-5.png'),
    icon: require('@/assets/images/icon-8-5.png'),
    title: '企业',
    tip: '企业的创新与突破，我们来解决',
  },
  {
    bg: require('@/assets/images/bg-8-3.png'),
    icon: require('@/assets/images/icon-8-3.png'),
    title: '研究机构',
    tip: '企业的创新与突破，我们来解决',
  },
  {
    bg: require('@/assets/images/bg-8-4.png'),
    icon: require('@/assets/images/icon-8-4.png'),
    title: '政策',
    tip: '企业的创新与突破，我们来解决',
  },

]
<template>
  <div id="app">
    <headerNav />
    <!-- <elLoading v-if="showLoading" /> -->
    <div class="app-main">
      <div class="content" id="content" ref="content">
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
    <!-- <fixedModule @toTop="toTop"/> -->
  </div>
</template>

<script>
import headerNav from '@/components/header'
// import fixedModule from '@/components/fixedModule'
import elLoading from '@/components/loading'
import { getToken } from '@/utils/auth'

export default {
  data() {
    return {
      showLoading: true,
    }
  },
  components: { headerNav },
  created() {
    this.changeLoadingStatus()
    let token = getToken()
    if (token) {
      let userInfo =JSON.parse(sessionStorage.getItem('userInfo'))
      this.$store.commit('user/SET_AVATAR', userInfo.headImage)
    }
  },
  watch: {
    $route(newVal, oldVal) {
      let pathArr = newVal.path.split('/')

      // 页面回滚顶部
      this.$refs['content'].scrollTop = 0

      // 动态设置页面标题
      document.title = newVal.name

      this.$store.dispatch('setting/setActiveIndex', `/${pathArr[1]}`)
      localStorage.setItem('activeIndex', `/${pathArr[1]}`)
    },
  },
  methods: {
    toTop() {
      this.$refs['content'].scrollTop = 0
    },
    changeLoadingStatus() {
      setTimeout(() => {
        this.showLoading = false
      }, 1500)
    },
  },
}
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #f5f5f5
  min-width 1340px
  overflow hidden
  .container
    width 1200px
    margin 0 auto
.app-main
  /* 50 = navbar */
  height calc(100vh - 60px)
  width 100%
  position relative
  overflow hidden
  background #fff
.content
  width 100%
  height calc(100vh - 60px)
  background #fff
  overflow-y auto
  position relative
ul, li
  padding 0
  margin 0
  list-style none
.submit
  background-color #3C60BC !important
  color #fff !important
  border 1px solid #3C60BC !important
</style>

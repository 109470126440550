// 引入axios
import Vue from "vue";
import promise from "es6-promise";
import axios from "axios";
import store from "@/store/index";
import Cookie from "js-cookie";
import App from "@/App.vue";
import router from "@/router";
import { Notification } from "element-ui";
promise.polyfill();

export const baseURL =
  process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_URL : "/api";
export const uploadURL = baseURL;
const service = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
  timeout: 20000, // 请求超时 20s
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 是否为当前的请求加上请求头 token
    if (Cookie.get("token")) {
      config.headers["Authorization"] = Cookie.get("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const code = response.data.code;
    if (code != 200) {
      if (code == 201 || code == 20004 || code == 20005) {
        var vm = new Vue({
          router,
          store,
          render: (h) => h(App),
        }).$mount("#app");
        vm.$router.push({
          path: "/warn",
          query: {
            message: response.data.message,
          },
        });
        store.dispatch("user/logout").then(() => {
          store.commit("user/SET_TOKEN", null);
        });
      } else {
        Notification.warning({
          title: response.data.message,
        });
      }
      return Promise.reject("error");
    }
    return response.data;
  },
  (err) => {
    // 失败响应
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求无效，请检查参数是否正确!";
          break;

        case 401:
          err.message = "未经授权，访问被拒!";
          break;

        case 403:
          err.message = "拒绝访问!";
          break;

        case 404:
          err.message = `地址不存在!`;
          break;

        case 408:
          err.message = "请求超时!";
          break;

        case 500:
          err.message = "系统错误!";
          break;

        case 501:
          err.message = "该方法未实现!";
          break;

        case 502:
          err.message = "网管出错!";
          break;

        case 503:
          err.message = "服务不可用!";
          break;

        case 504:
          err.message = "网关请求超时";
          break;

        case 505:
          err.message = "HTTP版本不受支持";
          break;

        default:
      }
      if (err.response.data.error) {
        err.message = err.response.data.error;
      }
      Vue.prototype.$message.closeAll();
      // Vue.prototype.$message.error(err.message)
      // router.push({name: 'error', params: {message: err.message, status: err.response.status}})
    }
  }
);
export default service;

<template>
  <div class="nav" :class="isLogining ? 'nav-logining' : ''">
    <div class="header container" id="nav">
      <div class="header-left">
        <div class="logo">
          <img :src="logo" alt="" />
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo header-nav"
          mode="horizontal"
          :router="true"
          :background-color="isLogining ? '#fff' : '#031430'"
          :text-color="isLogining ? '#666666' : '#D1D6DE'"
          active-text-color="#FCB110"
          @select="handleSelect"
        >
          <template v-for="(item, index) in menus">
            <el-menu-item v-if="!item.hidden" :key="index" :index="item.path">
              {{ item.name }}
            </el-menu-item>
          </template>
        </el-menu>
        <div class="input" v-show="!isLogining">
          <el-input
            size="small"
            placeholder="搜索"
            suffix-icon="el-icon-search"
            v-model="searchName"
          ></el-input>
        </div>
        <!-- <div v-show="!isLogining">
          <i
            class="el-icon-shopping-cart-2"
            style="font-size: 32px; margin: 0 40px"
          ></i>
        </div> -->
      </div>
      <div class="right" v-show="!isLogining">
        <div class="btn" v-if="!hasLogin">
          <div class="logon-btn cursor" @click="login">登录</div>
          <div class="register-btn cursor" @click="register">注册</div>
        </div>
        <div class="msg" v-else>
          <el-dropdown class="avatar-container" trigger="click">
            <div class="avatar-wrapper">
              <img :src="avatar" class="user-avatar" />
              <i class="el-icon-caret-bottom" />
            </div>
            <el-dropdown-menu
              slot="dropdown"
              class="user-dropdown idc-dropdown"
            >
              <el-dropdown-item>
                <div @click="toCenter">个人中心</div>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">
                <span style="display: block">退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menus } from '@/utils/common'

export default {
  data() {
    return {
      menus,
      searchName: '',
    }
  },
  computed: {
    hasLogin() {
      return this.$store.state.user.token
    },
    avatar() {
      return this.$store.state.user.avatar ? this.$store.state.user.avatar : require('@/assets/images/avatar.png')
    },
    activeIndex() {
      return this.$store.state.setting.activeIndex
    },
    isLogining() {
      if (this.activeIndex === '/login' || this.activeIndex === '/register') {
        return true
      } else {
        return false
      }
    },
    logo() {
      if (this.activeIndex === '/login' || this.activeIndex === '/register') {
        return require('@/assets/logo.png')
      } else {
        return require('@/assets/logo.png')
      }
    },
  },
  created() {
  },
  methods: {
    handleSelect(key, keyPath) {
      
    },
    login() {
      this.$router.push('/login')
    },
    register() {
      this.$router.push({
        path: '/forgetPassword',
        query: {
          type: 1,
        },
      })
    },
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$store.commit('user/SET_TOKEN', null)
        this.$message({
          message: '已退出，当前为未登录状态',
          type: 'warning',
        })
        sessionStorage.clear()
        this.$router.push('/')
      })
    },
    toCenter() {
      // window.open("http://36.159.108.15:885", "_blank");
      sessionStorage.setItem('setActive', 'info')
      sessionStorage.setItem('setIndex', 0)
      this.$router.push('userCenter')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/common.styl'
/deep/.el-input__inner
  border-radius 20px
  background-color #031430
  width 300px
.nav
  width 100%
  background $main-bg
  transition all 0.2s
  &.nav-logining
    background #fff
  .header
    height 60px
    display flex
    align-items center
    justify-content space-between
    .header-left
      display flex
      align-items center
    .logo
      img
        display block
        height 32px
        width auto
    .line
      margin 0 40px
      width 1px
      height 20px
      background #707070
      font-weight bold
    .input
      margin-left 40px
    .right
      display inline-flex
      align-items center
      .btn
        display inline-flex
        font-size 16px
        color #D1D6DE
        *
          padding 0 10px
      .avatar-container
        margin-right 30px
        .avatar-wrapper
          margin-top 5px
          position relative
          .user-avatar
            cursor pointer
            width 40px
            height 40px
            border-radius 10px
            object-fit contain
          .el-icon-caret-bottom
            cursor pointer
            position absolute
            right -20px
            top 25px
            font-size 12px
</style>

<style lang="stylus">
.header-nav
  transition all 0.5s
.idc-dropdown
  padding 0 !important
  .el-dropdown-menu__item
    list-style none
    line-height 36px
    padding 0 20px
    margin 0
    font-size 14px
    color #606266
    cursor pointer
    outline 0
    text-align center
  .el-dropdown-menu__item--divided
    margin-top 0
  .el-dropdown-menu__item--divided:before
    height 0
</style>
